@import "~antd/dist/antd.css";

body {
  background-color: #0f2139;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* For mobile phones: */
[class*="Tip-Row"] {
  width: 100%;
  padding-top: 8px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Tip-Row {
    width: 100%;
    padding-top: 8px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .Tip-Row {
    width: 100%;
    padding-top: 8px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Tip-Row {
    width: 100%;
    padding-top: 8px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .Tip-Row {
    width: 50%;
    padding-top: 8px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .Tip-Row {
    width: 50%;
    padding-top: 8px;
  }
}
